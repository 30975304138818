import React from "react"
import automationHeader from "../../../assets/images/products/netversys/automation_header.svg"
import wmVollautomatisierung from "../../../assets/images/products/netversys/wm-vollautomatisierung.svg"
import project3 from "../../../assets/images/products/netversys/automatisierung_platzhalter.jpg"
import Sidebar from "./Sidebar"
import { Col, Container, Row } from "react-bootstrap"
import WichtigeMerkmale from "../WichtigeMerkmale"
import LexikonBadgesRandomSection from "../../lexikon/LexikonBadgesRandomSection"

const Vollautomatisierung = () => {

  return (
    <>
      <section className="services-details-area ptb-100">
        <Container className="mt-5">
          <Row>
            <Col lg={8} md={12}>
              <div className="services-details-image">
                <img src={automationHeader} alt="Vollautomatisierung" />
              </div>

              <div className="services-details-desc">
                <span className="sub-title">NETVERSYS</span>
                <h1 className={"pt-3"}>Versandabwicklung / Vollautomatisierung</h1>
                <p>
                  NETVERSYS kann vollständig automatisiert betrieben werden. Dies ermöglicht eine lückenlose Integration
                  der Software in ein bestehendes Logistiksystem oder eine automatisierte Verpackungs- und
                  Versandstraße und resultiert in eine sehr effiziente Versandabwicklung.
                  In dieser Betriebsart werden Datenströme einer Schnittstelle autark vom System
                  verarbeitet. Eine Interaktion mit der Benutzeroberfläche ist dabei nicht mehr erforderlich oder
                  gewollt. Weiterhin kann entschieden werden, ob NETVERSYS die Versandlabel selbst druckt oder die
                  Druckdaten für ein Abnehmersystem bereitstellt. Da in dieser Betriebsart NETVERSYS unsichtbar, quasi
                  als Engine unter der Haube, operiert, bezeichnen wir diese Betriebsart auch als Blackbox-Modus.
                </p>
                <p>
                  Auch der Abrechnungslauf (Tagesabschluss) kann automatisiert betreiben werden. Hierbei kann für jeden
                  Carrier eingestellt werden, wann ein Abrechnungslauf gestartet werden soll. Damit ist dann auch die
                  NETVERSYS-Administrationskonsole für den täglichen Arbeitsablauf überflüssig.
                </p>
                <p>
                  Da NETVERSYS als ein sehr flexibles System gestaltet wurde, ist es trotzdem möglich die
                  vollautomatisierten Arbeitsabläufe parallel mit manuellen Arbeitsmethoden zu nutzen. Damit werden
                  etwaige Prozesse die nur schwer voll automatisierbar sind, nicht außen vor gelassen. In jedem Fall
                  ist ein gezielter Fokus auf eine optimale Versandabwicklung mit NETVERSYS jederzeit möglich.
                </p>

                <WichtigeMerkmale items={[
                  "Fehlerfreie Automatisierung",
                  "Anbindung verschiedener Hardware",
                  "regelbasierter Versand",
                  "Headless-Betrieb"
                ]} image={<img src={wmVollautomatisierung}
                               alt="Vollautomatisieung Versand Merkmale"
                               className={"ml-lg-4 ml-0"} />} />

                <h3>Relevantes aus dem Lexikon</h3>

                <LexikonBadgesRandomSection />
              </div>
            </Col>

            <Col lg={4} md={12}>
              <Sidebar />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="services-area pt-4 pb-70 bg-f1f8fb">
        <Container>
          <span className="mb-2 text-primary"><i className={"bx bxs-bullseye"} /> Im Detail</span>
          <h2>Automatisierung im Versand</h2>
          <Row className={"mt-5"}>
            <Col md={6} xs={12}>
              <img src={project3} alt="Vollautomatisierung" />
            </Col>
            <Col md={{ offset: 1, span: 5 }} xs={{ offset: 1, span: 11 }}>
              <p>
                NETVERSYS als serverbasierte Versandsoftware ist in der Lage "headless" zu operieren. Das bedeutet, die
                nötigen Eingaben müssen nicht manuell übertragen werden, sondern auf einen intelligenten Datenfluss
                zwischen den Systemen zurückgegriffen wird.
              </p>
              <p>
                Dies ist zum einen weniger fehleranfällig, meist effizienter und ermöglicht darüber hinaus den Betrieb
                von Versandstraßen bzw. vollautomatischen Applizierdruckern. Dabei binden wir nicht nur Druck- und
                Applizierhardware an, sondern erfassen auch automatisch Gewichte.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Vollautomatisierung
