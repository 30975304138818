import React from "react"
import Layout from "../../../components/App/Layout"
import Navbar from "../../../components/App/Navbar"
import Footer from "../../../components/App/Footer"
import Vollautomatisierung from "../../../components/ProductDetails/netversys/Vollautomatisierung"
import Seo from "../../../components/App/Seo"

const VollautomatisierungPage = () => {
  return (
    <Layout>
      <Seo title={"Perfekte Software für Versandabwicklung mit Vollautoamtisierung"}
           description={"Die Vollautomatisierung in NETVERSYS ist für viele Branchen ein sehr guter Hebel, " +
           "die Versandabwicklung durch Versandstraßen effizienter zu gestalten."}
           image={"/images/og/netversys-main-og.png"}>
        <script type="application/ld+json">{`
              {
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [{
                  "@type": "ListItem",
                  "position": 1,
                  "name": "Home",
                  "item": "https://logentis.de"
                },{
                  "@type": "ListItem",
                  "position": 2,
                  "name": "Versandsoftware",
                  "item": "https://logentis.de/versandsoftware/"
                },{
                  "@type": "ListItem",
                  "position": 3,
                  "name": "Vollautomatisierung",
                  "item": "https://logentis.de/versandsoftware/netversys/vollautomatisierung/"
                }]
              }
              `}
        </script>
      </Seo>
      <Navbar />
      <Vollautomatisierung />
      <Footer />
    </Layout>
  );
}

export default VollautomatisierungPage
